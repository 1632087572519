<template>
  <div class="main-cont">
    <div class="loginbox"></div>
    <div class="logininner">
      <h3>ADS-Hub</h3>
      <el-form :model="loginForm" status-icon :rules="rules" ref="loginForm" class="demo-ruleForm" size="mini">
        <el-form-item prop="email">
          <el-input type="email" v-model="loginForm.email" autocomplete="off"
            prefix-icon="iconfont icon-024weiduxinxi"></el-input>
        </el-form-item>
        <el-form-item prop="pwd">
          <el-input :type="flag ? 'text' : 'password'" v-model="loginForm.pwd" autocomplete="off"
            prefix-icon="iconfont icon-mima" @keyup.native.enter="submitForm('loginForm')">
            <template #suffix>
              <i class="iconfont" :class="flag ? 'icon-yanjing' : 'icon-yanjing-guan'" @click="flag = !flag"></i>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" class="loginBtn" @click="submitForm('loginForm')" :loading="isClick">Login</el-button>
        </el-form-item>
      </el-form>
      <div class="findpwd">
        <router-link to="/forgotpassword">Forgot password?</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { validateEmail, validatePwd } from '@/utils/validate.js'
// 引入封装的本地存储
import local from '@/utils/local.js'
// 引入api中的对应路径的文件 接收axios
import { login } from '../api/user.js'

export default {
  data () {
    return {
      // 按键加载
      isClick: false,
      // 定义标杆
      flag: false,
      loginForm: {
        email: '',
        pwd: ''
      },
      rules: {
        email: [{ validator: validateEmail, trigger: 'blur' }],
        pwd: [{ validator: validatePwd, trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 验证成功
          const data = await login({
            email: this.loginForm.email,
            password: this.loginForm.pwd
          })
          // console.log(data)
          if (data.status === 200) {
            const role = data.data.info.role
            this.isClick = true
            this.$message({
              message: 'Login successfully',
              type: 'success'
            })
            setTimeout(() => {
              // 把token存到本地存储
              local.set('hubTok', data.data.token)
              local.set('role', role)
              local.set('status', JSON.stringify(data.data.config.job_status))
              local.set(
                'name',
                data.data.info.firstname + data.data.info.lastname
              )
              this.$router.push('/')
              this.isClick = false
            }, 2000)
          } else {
            this.$message({
              message: data.data.toperr,
              type: 'error'
            })
            this.isClick = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main-cont {
  height: 100%;
}

.loginbox {
  height: 100%;
  width: 100%;
  background: url(../assets/images/01.jpg) center center no-repeat;
  background-size: 100% 100%;
  filter: blur(5px);
}

.logininner {
  width: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  padding: 5px 10px;

  h3 {
    text-align: center;
    padding: 20px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #2d3a4b;
  }

  /deep/.el-input {
    input {
      background: #fff;
      color: #333;
    }
  }

  .findpwd {
    padding: 8px;

    a {
      color: #409eff;
      text-decoration: underline;
    }
  }
}

.loginBtn {
  width: 100%;
}
</style>
